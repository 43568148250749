import { toCurrencyDisplay, toHumanReadableCurrencyDisplay } from "../../utils/currency.util";
import { getPropertyImageUrl } from "../../utils/image.utils";
import { toPercentageDisplay } from "../../utils/string.util";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ImgBath } from "../../assets/img/common/property/bath.svg";
import { ReactComponent as ImgBed } from "../../assets/img/common/property/bed.svg";
import { AppRoute } from "../../routes";
import { useSettings } from "../../context/settings";
import ArrowUPSvg from "../../assets/svgs/arrow-up.svg";
import { PropertyPledge, PropertyPledgeStatus, PropertyStatus } from "../../__generated__/graphql";

export function PropertyCard({ property, showFinancials = true, selected = false, className, selectedTab }: { property: any, showFinancials?: boolean, selected?: boolean, selectedTab?: string, className?: string, }) {
  const { settings } = useSettings(); // Get settings from the context
  const [mainImage, setMainImage] = useState<{ id: string, ext: string } | null>(null);


  useEffect(() => {
    if (selected) {
      const element = document.querySelector('.property-card.selected');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selected]);

  useEffect(() => {
    if (!property || !property.images) {
      return;
    }
    const sortedImages = property.images.slice().sort((a: any, b: any) => a.order - b.order);
    if (sortedImages[0]) {
      setMainImage(sortedImages[0]);
    }
  }, [property]);

  const fundedPercentage = (property: any) => {
    const confirmedPledges = property.pledges.filter(
      (pledge: any) => pledge.status === PropertyPledgeStatus.Confirmed
    );
    const totalConfirmedAmount = confirmedPledges.reduce(
      (sum: any, pledge: any) => sum + pledge.amount,
      0
    );
    return ((totalConfirmedAmount / property.price) * 100).toFixed(2);
  }

  const cardContent = (
    <>
      {!(property.status === PropertyStatus.ComingSoon) && <>
        <div className={`property-tag-left ${property?.isFunded ? 'green' : ''}`}>
          <span className="dot"></span>
          {/* {selectedTab === 'available' ? 'Available' : selectedTab === 'funded' ? 'Funded' : 'Exited'} */}
          { !property?.isFunded ? 'Available' : 'Funded' }
        </div>
        <div className="property-tag-right">
          <span className="dot"></span>
          Investors: {property.isFunded === false ? property.pledges?.filter(
            (pledge: PropertyPledge) =>
              pledge.status === "Confirmed"
          ).length || "0" :
            property.finalInvestorsCount
          }
        </div>
      </>
      }

      {mainImage && <img className="property-image" src={getPropertyImageUrl(property.id, mainImage, 400, 200)} alt={property.title} />}
      <div>
        {!(property.status === PropertyStatus.ComingSoon) &&
          <div className="property-attributes">
            <div className="property-area">
              <div>
                <ImgBed />&nbsp; {property.bed}
              </div>
              <div className="property-area-separator"></div>
              <div>
                <ImgBath />&nbsp; {property.bath}
              </div>
              <div className="property-area-separator"></div>
              <div className="property-area-value">
                {property.sqFootage} sq.ft
              </div>
            </div>
            {/* <div className="property-progress-wrapper"> */}
            <div className="property-progress">
              <div className="progress-text-container">
                <span className="funded-tag">Funded</span>
                <div className="property-progress-text">
                  {property.isFunded
                    ? "100"
                    : property.manualFunded === null
                      ? fundedPercentage(property)
                      : property.manualFunded / 100
                  }%
                </div>
              </div>
              <div className="progress-container">
                <div className="property-progress-container">
                  <div className="property-progress-bar" style={{
                    width: `${property.isFunded
                      ? "100"
                      : property.manualFunded === null
                        ? fundedPercentage(property)
                        : property.manualFunded / 100
                      }%`,
                  }}></div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>}

        <div className="property-content">

          {property.status === PropertyStatus.ComingSoon && (<div className="property-comingsoon">Coming Soon</div>)}



          <div className={`${property.status === PropertyStatus.ComingSoon ? "blur" : ""}`}>
            <div className="property-title">{property.title}</div>
            <div className="property-details-container">
              <div className="price-info">
                <span className="price-info-text">
                  <span className="price-dot"></span>
                  Price: {toHumanReadableCurrencyDisplay(property.price)}
                </span>
              </div>
              <div className="rental-info">
                <span className="rental-info-text">
                  <span className="rental-dot"></span>
                  Rental: {toCurrencyDisplay(property.financials[0].annualRent)} p.a
                </span>
              </div>
            </div>

            <div className="property-description " dangerouslySetInnerHTML={{ __html: property?.excerpt || '' }} />

            {showFinancials && property.financials && !!property.financials.length &&
              <table className="financial-table">
                <tr>
                  <td className="roi-label">3 Years Projected ROI</td>
                  <td><img src={ArrowUPSvg} alt="Arrow Up" /></td>
                  <td className="roi-percentage">{toPercentageDisplay(property.financials[0].year3ProjectedAnnualRoIPercentage)}</td>
                  <td className="roi-currency">{toHumanReadableCurrencyDisplay(property.financials[0].year3ProjectedAnnualRoIValue, 0)}</td>
                </tr>
                <tr>
                  <td className="roi-label">Projected Annual ROI</td>
                  <td><img src={ArrowUPSvg} alt="Arrow Up" /></td>
                  <td className="roi-percentage">{toPercentageDisplay(property.financials[0].projectedAnnualRoIPercentage)}</td>
                  <td className="roi-currency">{toHumanReadableCurrencyDisplay(property.financials[0].projectedAnnualRoIValue, 0)}</td>
                </tr>
                <tr>
                  <td className="roi-label">Income per Annum</td>
                  <td><img src={ArrowUPSvg} alt="Arrow Up" /></td>
                  <td className="roi-percentage">{toPercentageDisplay(property.financials[0].annualRent / (property.agreementPrice || property.price) * 100 * 100)}</td>
                  <td className="roi-currency"></td>
                </tr>
              </table>
            }
          </div>
        </div>
      </div>
    </>
  );

  return (
    settings?.blurProperties ? (
      <a className={`property-card property-card-blur ${selected ? 'selected' : ''} ${className}`}>
        {cardContent}
      </a>
    ) : (
      <Link to={
        property.status === PropertyStatus.ComingSoon ? "javascript:void(0)" :
          `${AppRoute.Property.replace(':slug', property.slug).replace(':id', property.id)}`

      }
        className={`property-card ${selected ? 'selected' : ''} ${className}`}>
        {cardContent}
      </Link>
    )
  );
}
